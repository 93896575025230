import React, { useEffect, useRef } from 'react';

const HubspotForm = () => {
    const formContainerRef = useRef(null);

    useEffect(() => {
        const loadForm = () => {
            if (window.hbspt && formContainerRef.current) {
                console.log('HubSpot script loaded, creating form...');
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: "144478452",
                    formId: "15bf3f8d-d8a6-42f4-98a3-948f1efc0456",
                    target: '#hubspotFormContainer',
                    onFormReady: (form) => {
                        console.log('HubSpot form created successfully', form);
                    },
                });
            } else {
                console.log('HubSpot script or target element not ready, retrying...');
                setTimeout(loadForm, 100);
            }
        };
        loadForm();
    }, []);

    return <div style={{ maxWidth: "740px", background: "#fff", padding: "16px", boxSizing: "border-box"}}>
        <div
            id="hubspotFormContainer"
            ref={formContainerRef}>
        </div>
    </div>;
};

export default HubspotForm;